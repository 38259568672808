export default [

      {
         path: '/',
         name: 'inicio',
         component: () => import('views/paginas/index.vue'),
         meta: {
         layout:'travel',
            resource:'home',
            action:'read',
         },
      },
      {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@views-core/error/Error404.vue'),
      meta: {
         layout: 'full',
         resource: 'Auth',
         action: 'read',
      },
   },
   {
      path: '/login',
      name: 'login',
      component: () => import('@views-core/pages/authentication/Login.vue'),
      meta: {
         layout: 'full',
         resource: 'Auth',
         redirectIfLoggedIn: true,
      },
   },

   

]
