import 'core-js/stable';
import 'regenerator-runtime/runtime'; // Si necesitas soporte para async/await


import Vue from 'vue'
import '@/assets/scss/app.scss'
Vue.prototype.$eventHub = new Vue();

import './libs/axios.js'
import router from './router'
import store from './store'
import i18n , {loadLocaleAsync} from '@/libs/i18n'

import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins

import './libs/acl'
import './libs/toastification'


import '@core/assets/fonts/feather/iconfont.css'
import '@core/scss/core.scss'
import '@/assets/scss/style.scss'


import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false


let app = new Vue({
  data:() => ({
    api_uri:import.meta.env.VITE_API_URL
  }),
  router,
  store,
  i18n,
  render: h => h(App),
   provide:() =>  ({swal : Vue.swal,i18n:i18n,loadLocaleAsync:loadLocaleAsync})
}).$mount('#app')
