
export default {

	namespaced:true,

	state:() => ({

			usuario: {
				username:null,
				id: null,
				nombre:null,
				apellido:null,
				email: '',
				avatar: '',
			},

			user: {
				username:null,
				id: null,
				nombre:null,
				apellido:null,
				email: '',
				avatar: '',

			},

			usuarios: [],

		}),


	getters:{
		avatar:(state) => state.usuario.avatar,
	},

	mutations:{

		cargarUser(state,data){
			localStorage.setItem('userData',JSON.stringify(data))			
			state.usuario = {...state.usuario,...data};
		},
		limpiarUsuario(state){

			state.usuario = {
					username:null,
				id: null,
				nombre:null,
				apellido:null,
				email: '',
				avatar: '',

			}
		}

	},

	

	actions:{

		cargarUsuario({commit}){

				let options = {
					'headers': {
						'WWW-Authenticate': 'Bearer', 'Authorization': localStorage.getItem('token')
					}
				}
				return new Promise((resolve, reject) => {
					axios.get(`/api/auth/user/`,null,options).then(({data}) => {
						commit('cargarUser',data)
						resolve(data)
					}).catch(e => reject(e))

				})

		}
	}
} 