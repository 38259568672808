
import store from '@/store'
import router from '@/router'
import {ref,computed} from 'vue'
import { initialAbility } from '@/libs/acl/config'
import ability from '@/libs/acl/ability'

export default function useAuth(){
   const usuario = computed(() => store.state.usuario.usuario)

  

   const formValidate = ref(null)

   const formulario = ref({
      email:'',
      password:'',
      // remember:true
   })

   const logout = () => {


      store.dispatch('cerrarSesion').then(({data}) => {
         localStorage.removeItem('token')
         localStorage.removeItem('habilidades');
         localStorage.removeItem('userData');
         // localStorage.removeItem('usuarioId')

         store.commit('usuario/limpiarUsuario')
         ability.update(initialAbility)
         

         router.push({name:'login'})
         window.location.reload();
      }).catch(e => {
         if(e.response.status === 419 ){
            router.push({name:'login'})
         }

      })

     
   }

   const login = () => {


      return new Promise((resolv,reject) => {

            axios.post('/api/login', formulario.value,{
               mode: 'no-cors',
               headers:{
                  'Access-Control-Allow-Origin': '*',
                  Accept: 'application/json',
                  "Content-Type":"application/json",
                  
               },
               withCredentials: false,
               credentials: 'same-origin',
               crossdomain: true,
            }).then(({ data }) => {

                  if(data.success){
                     axios.defaults.headers.common = { 'Authorization': `Bearer ${data.token}` }

                     store.commit('usuario/cargarUser', {nombre:data.nombre,avatar:data.avatar});
                     store.commit('setToken', data.token);

                     localStorage.setItem('token', data.token);
                     localStorage.setItem('habilidades', JSON.stringify([
                        {action:'read',subject:'home'},
                        {action:'write',subject:'home'},
                        {action:'update',subject:'home'},
                        {action:'delete',subject:'home'},
                        {action:'read',subject:'all'},
                        {action:'write',subject:'all'},
                        {action:'update',subject:'all'},
                        {action:'delete',subject:'all'},
                         {action:'read',subject:'Auth'},
                        {action:'write',subject:'Auth'},
                        {action:'update',subject:'Auth'},
                        {action:'delete',subject:'Auth'},
                     ]));
                     localStorage.setItem('userData', JSON.stringify({...store.state.usuario.usuario}));
                     ability.update(JSON.parse(localStorage.getItem('habilidades')));
                  }
                
                  resolv(data)

            }).catch((error) => {

              
               if (error.response && error.response.status === 422) {
                  formValidate.value.setErrors(error.response.data.errors)
               }

               reject(error)

            })


      })
   }

   return {
      logout,
      login,
      formValidate,
      formulario,
      is_loggin:computed(() => usuario.value.nombre ? true : false),
   };

}
